<template>
  <div class="user">
    <div class="bacBox">
      <div class="content">
        <div class="box-content">
          <!-- 小盒子2 -->
          <div class="userCent">
            <div class="c_nav">
              <div class="nav_main">
                <div :class="tabId == item.id ? 'is-active' : ''" @click="qiehuana(item)" v-for="(item, index) in list"
                  :key="index" :label="item.title" :name="item.id">
                  <div v-if="tabId == item.id" class="left_line"></div>
                  <div style="display:flex;flex-direction: column;align-items: center;" slot="label">
                    <img style="width: 20px;margin-bottom: 5px;" :src="item.img" alt="">
                    {{
                      item.title }}
                  </div>
                </div>
              </div>
              <!-- <el-tabs v-model="tabId" tab-position="left" @tab-click="tabChange">
                <el-tab-pane v-for="(item, index) in list" :key="index" :label="item.title" :name="item.id">
                  <div style="display:flex;flex-direction: column;align-items: center;" slot="label">
                    <img style="width: 20px;" :src="item.img" alt="">
                    {{
                      item.title }}
                  </div>
                </el-tab-pane>

              </el-tabs> -->
              <div
                style="padding: 0 20px; background: #fff; min-height: 620px;flex: 1;border-radius: 0 8px 8px 8px;padding-bottom: 20px;">
                <router-view />
              </div>
            </div>
            <!-- 右侧盒子 -->
          </div>
        </div>
        <!-- <div class="youlove">
        <timetable></timetable>
      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { getUserCourseStatistics } from '@/api/user'
import Timetable from '@/views/mine/my/TimetableCcolleges.vue'
import {
  selectPcStuClassIdDetails,
  getNoPastMClassCourseList,
  getPCClassRate
} from '@/api/mykecheng'
import { set } from 'date-fns'
export default {
  components: { Timetable },
  data() {
    return {
      userInfo: null,
      userId: '',
      list: [
        {
          id: '10',
          title: '最近直播',
          url: '/partnerPage/recentLive',
          icon: 'icon-wodekecheng',
          img: require('@/assets/img/learn/c1.png')
        },
        {
          id: '1',
          title: '我的课程',
          url: '/partnerPage/mycurriculum',
          icon: 'icon-wodekecheng',
          img: require('@/assets/img/learn/c2.png')
        },
        {
          id: '4',
          title: '学习资料',
          url: '/partnerPage/mydata',
          icon: 'icon-a-zu320',
          img: require('@/assets/img/learn/c3.png')
        },
        {
          id: '3',
          title: '我的题库',
          url: '/partnerPage/myexamination',
          icon: 'icon-a-zu319',
          img: require('@/assets/img/learn/c4.png')
        },
        {
          id: '5',
          title: '我的考场',
          url: '/partnerPage/myRoom',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/c5.png')
        },
        {
          id: '7',
          title: '我的作业',
          url: '/partnerPage/myOperation',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/c7.png')
        },
        {
          id: '2',
          title: '最近学习',
          url: '/partnerPage/study',
          icon: 'icon-xuexijilu',
          img: require('@/assets/img/learn/c6.png')
        },
        // {
        //   id: '6',
        //   title: '学习记录',
        //   url: '/partnerPage/learningRecord',
        //   icon: 'icon-xuexijilu',
        //   img: require('@/assets/img/learn/c8.png')
        // },
        // {
        //   id: '8',
        //   title: '我的错题',
        //   url: '/partnerPage/myWrongQuestion',
        //   icon: 'icon-xuexijilu',
        //   img: require('@/assets/img/learn/c9.png')
        // },
        {
          id: '9',
          title: '做题记录',
          url: '/partnerPage/problemSolving',
          icon: 'icon-xuexijilu',
          img: require('@/assets/img/learn/c10.png')
        }
      ],
      tabId: '1',
      chong: {},
      classlist: [],
      search: {},
      classIdDetails: {} // 学习率
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == '/login' || newVal.path == '/login') {
        this.userId = localStorage.getItem('userId')
      }
    }
  },
  async created() {
    console.log('0998776');
    this.userInfo = getInfo()
    this.userId = localStorage.getItem('userId')
    if (!this.userId) {
      Vue.prototype.goLoginView('mustBack')
      return
    }
    for (const item of this.list) {
      if (this.$route.path == '/partnerPage/myOperationDetails') {

        if ('/partnerPage/myOperation' == item.url) {
          this.tabId = item.id
          break
        }
      } else {
        if (this.$route.path == item.url) {
          this.tabId = item.id
          break
        }
      }

      console.log(this.$route.path, 'this.$route.path');
    }
    // 滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        const top = $(window).scrollTop()
        if (top >= 290) {
          $('.userCentLeft').addClass('fixed-card')
          $('.zhicheng').removeClass('dis-none')
        } else {
          $('.userCentLeft').removeClass('fixed-card')
          $('.zhicheng').addClass('dis-none')
        }
      })
    })
  },
  methods: {
    qiehuana(item) {
      console.log(item, 'item');
      if (this.tabId == item.id) {
        return
      }
      this.tabId = item.id
      // this.$set(this, 'tabId', item.id)

      this.$router.replace(item.url)

    },
    tabChange(tab) {
      console.log(tab, 'tab');
      let items = ''
      for (let item of this.list) {
        if (item.id == this.tabId) {
          items = item
          break;
        }
      }
      // this.tabId = item.id

      console.log(items, 'items');
      // this.$router.replace(items.url)
    },
    getDetail(item) {
      this.search.className = item.name
      this.search.class = item.type + ',' + item.coursesId
      /* 班型学习率 */
      const data = {
        userId: this.userInfo.id,
        classId: this.search.class,
        tenantId: this.userInfo.tenantId
      }
      getPCClassRate(data).then((res) => {
        this.classIdDetails = res.data
      })
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
/* /deep/ .el-select {
  width: 100%;
  margin-top: 20px;
  .el-input__inner {
    height: auto !important;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    padding: 0px;
    background: none !important;
    width: 100%;
    border: none !important;
    overflow: hidden; //超出一行文字自动隐藏
    text-overflow: ellipsis; //文字隐藏后添加省略号
    white-space: nowrap; //强制不换行
  }
  .el-input__suffix {
    display: none;
  }
} */
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.el-dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

/deep/ .dropBox {
  margin-bottom: 16px;
  margin-top: 28px;
  max-width: 600px;

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;

    .iconfont {
      font-size: 10px;
      color: #fff;
      margin-left: 8px;
      flex-shrink: 0;
    }
  }
}

// 大盒子
.user {
  width: 100%;
  height: auto;
  background: #f5f6fa;
  background-size: 100% 240px;
  background-repeat: no-repeat;
  overflow: hidden;

  .utop {
    box-sizing: border-box;
    width: 100%;
    height: 160px;

    background-image: url('~@/assets/img/homeSeventh/mineBg.png');
    background-size: 100% 100%;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;

    .ucet {
      width: 1200px;
      margin: 0 auto;

      .userTop {
        height: 100px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;

        // 左侧
        .userTopLeft {
          width: 100%;
          display: flex;
          align-items: center;

          // 头像
          .userImg {
            width: 80px;
            height: 80px;
            opacity: 1;
            border: 2px solid #ffffff;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 16px;
          }

          //  昵称
          .nickName {
            font-size: 22px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }

          .hi {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            margin-top: 20px;
          }

          .iconfont {
            font-size: 10px;
            flex-shrink: 0;
            flex-shrink: 0px;
            color: #fff;
          }
        }

        // 右侧
        .userTopRight {
          width: auto;
          height: 45px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          // 斜线
          .userTopCents {
            width: 1px;
            height: 100%;
            background: #efefef;
            transform: rotate(20deg);
            margin: 0px 30px;
          }

          // 内容
          .userTopCent {
            height: 100%;
            width: max-content;
            padding: 0px 11px;
            text-align: center;

            .title {
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 14px;
            }

            .text {
              font-size: 24px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 24px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  /* 标题导航栏 */
  .c_nav {
    width: 100%;
    // background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;


  }

  // 小盒子2
  .userCent {
    width: 1200px;
    height: auto;
    margin: 0 20px 0;
    margin-bottom: 30px;
    // padding: 0px 24px 24px;
    // background: #fff;
    border-radius: 8px 8px 8px 8px;

    // 小盒子2左侧
    .userCentLeft {

      // 单个模块盒子
      .userCentLefts {
        width: 100%;
        height: auto;
        margin-top: 27px;

        // 单个模块盒子标题
        p {
          width: 100%;
          height: 40px;
          font-size: 16px;
          text-align: center;
          line-height: 40px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-left: 3px;
        }

        // 单个模块盒子内容
        .userCentLeftsC {
          width: 100%;
          height: 36px;

          // 右侧列表内容
          .userCentLeftsCr {
            width: 100%;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
            padding-left: 58px;
            border-left: 3px solid #fff;
          }
        }

        // 单个模块盒子内容点击后样式
        .on {
          background: #fcefed;

          .userCentLeftsCr {
            color: #ff4027;
            border-left: 3px solid #ff4027;
          }
        }
      }
    }
  }
}

.zhicheng {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}

.bacBox {
  width: 100%;
  background-image: url("~@/assets/img/partner/bac.png");
  background-repeat: no-repeat;
  padding-top: 20px;

  .content {
    width: 1200px;
    margin: 0px calc(50% - 600px);
    display: flex;
    justify-content: space-between;

    .box-content {
      width: 1020px;
    }

    .youlove {
      width: 360px;
      flex-shrink: 0;
    }
  }
}

.nav_main {
  width: 110px;
  flex-shrink: 0;
  max-height: 620px !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  background: #F5F7F9;
  border-radius: 8px 0 0 8px;

  >div {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 110px;
    min-height: 70px;
    // background: #fff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    padding: 15px 0;

    .left_line {
      position: absolute;
      left: 0;
      width: 4px;
      height: 100%;
      background: #2586f5;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  .is-active {
    width: 110px;
    height: 80px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    color: #0270E8;
  }
}

::-webkit-scrollbar {
  width: 0;
}
</style>
